<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-form inline>
          <el-form-item>
            <el-input type='text' v-model='searchCondition.original_name' placeholder='原文件名称:模糊搜索' clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input type='text' v-model='searchCondition.full_path' placeholder='图片链接，前缀搜索'
                      style='width: 400px'
                      clearable>
            </el-input>
          </el-form-item>
          <el-form-item label='创建时间'>
            <el-date-picker
              v-model='searchCondition.date_range'
              type='daterange'
              align='right'
              unlink-panels
              range-separator='至'
              start-placeholder='开始日期'
              end-placeholder='结束日期'
              value-format='yyyy-MM-dd'
              :picker-options='pickerOptions'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input type='text' v-model='searchCondition.size_gt' placeholder='单位 KB'
                      style='width: 400px'
                      clearable>
              <template slot='prepend'>
                大小 >
              </template>
              <template slot='append'>
                KB
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='handlerQuery'>搜索</el-button>
          </el-form-item>
        </el-form>
        <div class='mb8'>
          <el-row :gutter='15'>
            <el-col :span='1.5'>
              <el-button type='primary' icon='el-icon-plus' v-if="userPermissions.indexOf('attachment_create') !== -1"
                         @click='addButton(0)'>添加
              </el-button>
            </el-col>
            <el-col :span='1.5'>
              <el-button type='danger' icon='el-icon-edit' v-if="userPermissions.indexOf('attachment_create') !== -1"
                         @click='dialogFormVisible=!dialogFormVisible'>修改链接
              </el-button>
            </el-col>
            <el-col :span='1.5'>
              <el-button type='danger' icon='el-icon-delete' :disabled='multiple'>删除(开发中)</el-button>
            </el-col>
            <el-col :span='1.5'>
              <el-button icon='el-icon-setting' @click='customColumnShow=true'>列设置</el-button>
            </el-col>
            <el-col :span='4'>
              <el-tag effect='plain' size='small'> 大小：{{ $utils.byteToSize(summary.size || 0) }}</el-tag>
            </el-col>
          </el-row>
        </div>
        <div>
          <MSetTable ref='setTableRef' :showIndex='true' max-height='1000' :tableData='attachmentList'
                     :columns='columns'
                     :loading='tableLoading'
                     :border='true'
                     row-key='id'
                     @sort-change='changeTableSort'
                     @selection-change='handleSelectionChange'
          >
            <el-table-column type='selection' width='55' align='center' slot='first-column'></el-table-column>
          </MSetTable>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>
    </PageHeaderLayout>
    <ModalDialog :dialogData='dialogData' @dialogClose='dialogClose' @dialogConfirm='dialogConfirm'>
      <template slot='content'>
        <el-form :model='formData' :rules='rules' ref='attachmentForm' label-position='right' label-width='96px'>
          <el-row>
            <el-col :span='22'>
              <el-form-item label='名称' prop='title'>
                <el-input v-model='formData.title'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if='isAddStatus'>
            <el-col>
              <el-form-item label='附件' prop='attachment_id'>
                <ApeUploader :allow-type-list='attachmentType' :limit='1' :isImage='false'
                             uploadTip='请上传文件，最多上传1份，最大500MB。' :allowSize='1024*1024*500'
                             @handleUploadSuccess='handleUploadSuccess' @handleUploadRemove='handleUploadRemove'
                             :upload-file-list='uploadFileList'></ApeUploader>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </ModalDialog>

    <el-dialog title='链接域名修改' :visible.sync='dialogFormVisible'>
      <el-form ref='form' :model='form' size='small' label-width='100px' :rules='rulesDomain'>
        <el-form-item label='修改前域名' prop='org_domain'>
          <el-input v-model='form.org_domain' autocomplete='off'></el-input>
        </el-form-item>
        <el-form-item label='修改后域名' prop='domain'>
          <el-input v-model='form.domain' autocomplete='off'></el-input>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='dialogFormVisible = false'>取 消</el-button>
        <el-button type='primary' @click='updateDomain()'>确 定</el-button>
      </div>
    </el-dialog>
    <!--    列设置-->
    <MColumnSetting :visible.sync='customColumnShow' ref='columnSetting' @userChange='columnSettingChange'
                    :column='userColumns' :table-name='tableName' @back='columnSettingBack'
                    @save='columnSettingSave'></MColumnSetting>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import ApeUploader from '@/components/ApeUploader'
import { mapGetters } from 'vuex'
import MSetTable from '@/components/common/MSetTable'
import MColumnSetting from '@/components/common/MColumnSetting'

export default {
  name: 'AttachmentList',
  components: {
    MColumnSetting,
    MSetTable,
    PageHeaderLayout,
    ApeTable,
    ModalDialog,
    ApeUploader
  },
  data() {
    const equalToVal = (rule, value, callback) => {
      if (this.form.org_domain === value) {
        callback(new Error('两次输入的域名不能相同'))
      } else {
        callback()
      }
    }
    return {
      ids: [],
      multiple: true,
      dialogFormVisible: false,
      searchCondition: { user_id: null, full_path: null, original_name: '', date_range: [], size_gt: null },
      form: { org_domain: '', domain: '' },
      // 表单校验
      rulesDomain: {
        org_domain: [
          { required: true, message: '修改前域名不能为空', trigger: 'blur' }

        ],
        domain: [
          { required: true, message: '修改后域名不能为空', trigger: 'blur' },
          { required: true, validator: equalToVal, trigger: 'blur' }
        ]
      },
      tableName: 'attachment',
      tableLoading: true,
      customColumnShow: false,
      total: 0,
      orderSort: { 'id': 'asc' },
      // 分页信息
      pagingData: {
        page_size: 10,
        current_page: 1
      },
      userColumns: [
        { hide: true, prop: 'id', label: 'ID', width: 100, sortable: false, fixed: true },
        { hide: false, prop: 'full_path', label: '图片', width: 60, sortable: false, fixed: true, type: 'image' },
        { hide: false, prop: 'original_name', label: '原文件名称', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'filename', label: '文件名', width: 140, sortable: true, fixed: false },
        { hide: false, prop: 'title_alias', label: '名称', width: 140, sortable: false, fixed: false },
        { hide: false, prop: 'type', label: '类型', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'size', field: 'size_alias', label: '大小', width: 100, sortable: true, fixed: false },
        // { hide: false, prop: 'path_alias', label: '存储相对路径', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'path', label: '存储相对路径', width: 140, sortable: false, fixed: false },
        { hide: true, prop: 'full_path', label: '图片链接', width: 320, sortable: false, fixed: false },
        { hide: true, prop: 'file_hash', label: '文件hash值', width: 160, sortable: false, fixed: false },
        { hide: false, prop: 'username', label: '上传人', width: 100, sortable: false, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 140, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ],
      columns: [],
      // 附件类型
      attachmentType: ['image/tiff', 'image/bmp', 'image/jpg', 'image/gif', 'image/jpeg', 'image/png', 'application/pdf', 'application/caj', '', 'application/zip', 'application/rar', 'application/doc'],
      imageTypes: ['image/jpg', 'image/gif', 'image/jpeg', 'image/png', 'jpg', 'gif', 'jpeg', 'png'],
      // 表格列表数据
      attachmentList: [],
      // 分页信息
      // pagingData: {
      //   is_show: true,
      //   layout: 'total, sizes, prev, pager, next, jumper',
      //   total: 0
      // },
      // 分页的offset,序号列使用
      offset: 0,
      // 是否为增加状态
      isAddStatus: true,
      // 表单结构
      formData: { title: '' },
      // 已上传文件列表
      uploadFileList: [],
      // 表单验证
      rules: {
        title: [
          { required: true, message: '输入标题', trigger: 'blur' }
        ],
        attachment_id: [
          { required: true, message: '上传图片', trigger: 'blur', validator: this.apeUploaderVerify }
        ]
      },
      // 抽屉数据
      dialogData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '附件',
        width: '560px'
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      summary: { size: null }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    getDefaultColumns() {
      if (localStorage.getItem(`table-column:${this.tableName}`)) {
        const tableSet = JSON.parse(localStorage.getItem(`table-column:${this.tableName}`))
        let userColumn = tableSet.user_columns
        this.columns = [...userColumn]
        this.isColSet = true
      } else {
        this.isColSet = false
        this.columns = [...this.userColumns]
      }
    },
    columnSettingChange(userColumn) {
      //设置列-修改表显示
      // this.customColumnShow=false
      this.columns = [...userColumn]
      this.isColSet = true
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingBack() {
      this.customColumnShow = false
      this.isColSet = false
      this.getDefaultColumns()
    },
    // eslint-disable-next-line no-unused-vars
    columnSettingSave() {
      this.customColumnShow = false
      this.isColSet = true
    },
    updateDomain() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$confirm(`此操作将会把图片链接域名为“${this.form.org_domain}”改为“${this.form.domain}”，是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.updateDomainAttachment()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        } else {
          this.$notify.warning('请校对参数！！！')
        }
      })
    },
    async updateDomainAttachment() {
      let { total, startTime, endTime } = await this.$api.updateDomainAttachment(this.form)
      let usedTime = endTime - startTime
      this.msgSuccess(`共影响数据：${total},耗时：${usedTime}秒`)
    },
    // 响应添加按钮
    async addButton() {
      this.isAddStatus = true
      this.dialogData.loading_text = '玩命加载中……'
      this.dialogData.visible = true
      this.dialogData.title = '添加附件'
      this.$nextTick(() => {
        this.dialogData.loading = false
      })
    },
    // 响应编辑按钮
    async editButton(id) {
      this.isAddStatus = false
      this.dialogData.loading_text = '玩命加载中……'
      this.dialogData.visible = true
      this.dialogData.title = '编辑友情链接(ID：' + id + ')'
      let { info } = await this.$api.getAttachmentInfo(id)
      this.formData = info
      this.dialogData.loading = false
    },
    // 处理抽屉关闭
    dialogClose() {
      this.initFormData()
      this.dialogData.visible = false
      this.dialogData.loading = true
    },
    // 处理抽屉确认
    async dialogConfirm() {
      // 调用组件的数据验证方法
      this.$refs['attachmentForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.dialogData.loading_text = '玩命提交中……'
      this.dialogData.loading = true
      let id = await this.$api.saveAttachment(this.formData)
      this.$nextTick(() => {
        this.dialogData.visible = false
      })
      this.$nextTick(() => {
        if (id) {
          this.getList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteAttachment(id)
      if (info == 'ok') {
        this.$nextTick(() => {
          this.getList()
        })
      } else {
        this.$message.error(info)
      }

    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.formData = {}
        this.$refs['attachmentForm'].resetFields()
        this.uploadFileList = []
      })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    handleSearchCondition() {
      let cond = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          if (field === 'size_gt') {
            cond[field] = this.searchCondition[field] * 1024
          } else
            cond[field] = this.searchCondition[field]
        }

      })

      return cond
    },
    handlerQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    // 初始化附件列表
    async getList() {
      this.tableLoading = true
      let params = this.handleSearchCondition()
      Object.assign(params, this.pagingData, { order: this.orderSort })
      // let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let { list, pages } = await this.$api.getAttachmentList(params)
      this.attachmentList = []
      this.$nextTick(() => {
        this.attachmentList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
      })

      this.tableLoading = false
      let { summary } = await this.$api.getAttachmentSummary(params)
      this.summary = summary
    },
    // 附件上传成功回调
    handleUploadSuccess(file, fileList) {
      this.formData.attachment_id = file.id
      this.formData.title = file.title || ''
      this.uploadFileList = fileList
    },
    // 附件删除回调
    handleUploadRemove(file, fileList) {
      this.formData.attachment_id = 0
      this.uploadFileList = fileList
    },
    // 附件上传自定义验证
    apeUploaderVerify(rule, value, callback) {
      if (rule.required && !this.formData.attachment_id) {
        callback(new Error(rule.message))
      }
      callback()
    }
  },
  created() {
    this.getDefaultColumns()
  },
  mounted() {
    this.getList()
  }
}
</script>

